import React from "react"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "gatsby"

import { store } from "@/types/interface"
import { getNewCourses, getSiteInformation } from "../actions"
import { Layout } from "../components"
import { SEO } from "../components/common"
import { CourseCard } from "../components/common"
import styles from "./new.module.css"

const connector = connect(
  // mapStateToProps
  ({
    course,
    siteInformation,
  }: {
    course: store.Course
    siteInformation: store.SiteInformation
  }) => ({
    courseAbstracts: course?.courseAbstracts,
    siteInformation: siteInformation.siteInformation,
  }),
  // mapDispatchToProps
  { getNewCourses, getSiteInformation }
)

type Props = ConnectedProps<typeof connector>

const New: React.FC<Props> = ({ courseAbstracts, getNewCourses,siteInformation, getSiteInformation }) => {
  React.useEffect(() => {
    document &&
      document.documentElement.style.setProperty(
        "--color-primary",
        "rgb(140, 198, 63)"
      )
    getNewCourses()
    getSiteInformation()

    return () =>
      document.documentElement.style.setProperty("--color-primary", "#16424D")
  }, [])

  return (
    <Layout>
      <SEO title="Culture House 新講座" />
      <Link to="/flyer" className={styles.flyerLink}>
        <span>最新広告({siteInformation?.flyerTerm})</span>
        <span className={styles.arrow}></span>
      </Link>
      <div className="container">
        <h1 className={styles.title}>新講座</h1>
        {courseAbstracts?.map(course => (
          <CourseCard key={course._id} course={course} />
        ))}
      </div>
    </Layout>
  )
}

export default connector(New)
